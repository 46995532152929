import TentBanner from "./images/tent/TentBanner.jpg";
import Tent1 from "./images/tent/201806291812147821-3122-ac35cc5e200911ebb5f20242ac110003.jpg";
import Tent2 from "./images/tent/Tent2.jpg";
import Tent3 from "./images/tent/201806291812147821-f8e2a3aa1ff611eb99700242ac110003.jpg";

import deluxeNonViewBanner from "./images/deluxe-room-non-view/Banner.jpg";
import deluxeNonView1 from "./images/deluxe-room-non-view/Deluxe-Family-non-view.jpg";
import deluxeNonView2 from "./images/deluxe-room-non-view/201806291812147821-508055-1b78744204cd11eca22b0a58a9feac02.jpg";
import deluxeNonView3 from "./images/deluxe-room-non-view/201806291812147821-508055-1bf7484e04cd11ec9b0f0a58a9feac02.jpg";

import standardDeluxeVallleyViewBanner from "./images/standard-deluxe-valley-view/Picsart_23-08-27_13-56-19-977.jpg";
import standardDeluxeVallleyView1 from "./images/standard-deluxe-valley-view/banner.jpg";
import standardDeluxeVallleyView2 from "./images/standard-deluxe-valley-view/201806291812147821-89d2c7241ff611eb814b0242ac110003(1).jpg";
import standardDeluxeVallleyView3 from "./images/standard-deluxe-valley-view/201806291812147821-2730-379fc06003df11ec83d30a58a9feac02.jpg";

import deluxeBanner from "./images/Deluxe Room/banner.jpg";
import deluxe1 from "./images/Deluxe Room/201806291812147821-2730-3785bd6403df11eca1450a58a9feac02.jpg";
import deluxe2 from "./images/Deluxe Room/201806291812147821-2730-866d2c48ba2f11e88dc102e772c890b8.jpg";
import deluxe3 from "./images/Deluxe Room/201806291812147821-863ed67a1ff611eb86560242ac110003(1).jpg";


import deluxeFamilySuiteBanner from "./images/deluxe-family-suite-valley-view/banner1.jpg";
import deluxeFamilySuite1 from "./images/deluxe-family-suite-valley-view/ACVIEW.jpg";
import deluxeFamilySuite2 from "./images/deluxe-family-suite-valley-view/ACVIEW1.jpg";
import deluxeFamilySuite3 from "./images/deluxe-family-suite-valley-view/ACVIEW2.jpg";

export default [
  {
    sys: {
      id: "1"
    },
    fields: {
      name: "standard deluxe valley view",
      slug: "standard-deluxe-valley-view",
      type: "single",
      price: 100,
      size: 200,
      capacity: 5,
      pets: true,
      breakfast: false,
      featured: true,
      description:
        "Experience pure luxury in our Standard Deluxe Room with Valley View. Adorned with soothing tones, the room offers a King-sized bed and a well-appointed workspace. Floor-to-ceiling windows frame picturesque valley views, allowing you to connect with nature's beauty from the comfort of your room. The modern en-suite bathroom features a rejuvenating rain shower and elegant fixtures. Stay entertained with a flat-screen TV, and stay connected with high-speed Wi-Fi. The room's ambiance ensures relaxation, while the valley's panorama creates a serene backdrop. Whether you're sipping your morning coffee or enjoying a glass of wine at sunset, the valley's charm will captivate you. Modern comforts like climate control and a mini fridge enhance your stay. Immerse yourself in tranquility and let the Standard Deluxe Room with Valley View redefine your idea of relaxation.",
      extras: [
        "Toiletries, Bathroom Slippers, Towels",
        "Full-size executive writing desk",
        "Coffee and tea-making facilities, Mini - Fridge",
        "24X7 Room Service",
        "High-speed Wi-Fi and broadband Internet access",
        "Laundry Service, King Size Bed, Single bed, Extra Bed on request"
      ],
      images: [
        {
          fields: {
            file: {
              url: standardDeluxeVallleyViewBanner
            }
          }
        },
        {
          fields: {
            file: {
              url: standardDeluxeVallleyView1
            }
          }
        },
        {
          fields: {
            file: {
              url: standardDeluxeVallleyView2
            }
          }
        },
        {
          fields: {
            file: {
              url: standardDeluxeVallleyView3
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "2"
    },
    fields: {
      name: "tent suite",
      slug: "tent-suite",
      type: "double",
      price: 150,
      size: 250,
      capacity: 4,
      pets: true,
      breakfast: false,
      featured: false,
      description:
        "Experience a unique blend of luxury and nature in our Tent Suite with Valley View. Nestled in serene surroundings, this accommodation offers an exceptional escape from the ordinary. Step into your private sanctuary, where a spacious and elegantly appointed tent awaits. The interior seamlessly combines rustic charm with modern comforts, featuring a plush king-sized bed, cozy seating, and all the amenities you need for a relaxing stay. The en-suite bathroom offers both convenience and luxury, ensuring your comfort throughout your stay. Immerse yourself in the rugged elegance of our Tent Suite with Valley View and let nature's grandeur become a part of your unforgettable experience. Through the tent's large entrance, be greeted by an awe-inspiring vista of the valley stretching before you. Relax on your private outdoor deck and bask in the beauty of nature, whether it's the tranquility of sunrise or the magic of sunset.",
      extras: [
        "Toiletries, Towels",
        "Full-size executive writing desk",
        "Coffee and tea-making facilities, Mini - Fridge",
        "24X7 Room Service",
        "High-speed Wi-Fi and broadband Internet access",
        "Laundry Service, King Size Bed, Extra Bed on request"
      ],
      images: [
        {
          fields: {
            file: {
              url: TentBanner
            }
          }
        },
        {
          fields: {
            file: {
              url: Tent1
            }
          }
        },
        {
          fields: {
            file: {
              url: Tent2
            }
          }
        },
        {
          fields: {
            file: {
              url: Tent3
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "3"
    },
    fields: {
      name: "deluxe famiy non view",
      slug: "deluxe-falimy-non-view",
      type: "single",
      price: 250,
      size: 300,
      capacity: 5,
      pets: true,
      breakfast: false,
      featured: false,
      description:
        "Welcome to our Deluxe Family Room, a spacious haven designed with your family's utmost comfort in mind. This room seamlessly combines style and functionality to cater to your family's needs. With a thoughtfully arranged layout, the Deluxe Family Room offers ample space for relaxation and bonding. Unwind on comfortable beds while enjoying modern amenities like a flat-screen TV and high-speed Wi-Fi. The en-suite bathroom features convenient fixtures, ensuring a hassle-free experience for everyone. The tasteful decor creates a warm and inviting ambiance, making it the perfect retreat after a day of exploration. The room's generous size allows for seamless movement and relaxation, ensuring that each family member feels at home. Discover the joy of togetherness in our Deluxe Family Room and create lasting memories in a space designed exclusively for your family's comfort.",
      extras: [
        "Toiletries, Towels",
        "Full-size executive writing desk",
        "Coffee and tea-making facilities, Mini - Fridge",
        "24X7 Room Service",
        "High-speed Wi-Fi and broadband Internet access",
        "Laundry Service, King Size Bed, Extra Bed on request"
      ],
      images: [
        {
          fields: {
            file: {
              url: deluxeNonViewBanner
            }
          }
        },
        {
          fields: {
            file: {
              url: deluxeNonView1
            }
          }
        },
        {
          fields: {
            file: {
              url: deluxeNonView2
            }
          }
        },
        {
          fields: {
            file: {
              url: deluxeNonView3
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "4"
    },
    fields: {
      name: "deluxe",
      slug: "deluxe",
      type: "single",
      price: 300,
      size: 400,
      capacity: 3,
      pets: true,
      breakfast: false,
      featured: true,
      description:
        "Welcome to our Deluxe Room, where comfort and style intertwine to offer you a truly remarkable stay. The room's tastefully designed interior creates a soothing ambiance, ensuring a relaxing retreat. Sink into the plush bedding, where you'll find a cozy haven awaiting you after a day of exploration. The room features modern amenities including a flat-screen TV, high-speed Wi-Fi, and a well-appointed en-suite bathroom, providing convenience at your fingertips. Whether you're traveling for business or leisure, the Deluxe Room caters to your needs with a dedicated workspace, comfortable seating, and thoughtful touches that enhance your overall experience. Allow yourself to unwind in this haven of tranquility, where every detail is carefully curated to make your stay memorable. Whether it's a short getaway or an extended stay, the Deluxe Room offers the perfect blend of comfort and functionality, ensuring a truly enjoyable stay.",
      extras: [
        "Toiletries, Towels",
        "Full-size executive writing desk",
        "Coffee and tea-making facilities, Mini - Fridge",
        "24X7 Room Service",
        "High-speed Wi-Fi and broadband Internet access",
        "Laundry Service, King Size Bed, Extra Bed on request"
      ],
      images: [
        {
          fields: {
            file: {
              url: deluxeBanner
            }
          }
        },
        {
          fields: {
            file: {
              url: deluxe1
            }
          }
        },
        {
          fields: {
            file: {
              url: deluxe2
            }
          }
        },
        {
          fields: {
            file: {
              url: deluxe3
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "5"
    },
    fields: {
      name: "deluxe family suite valley view",
      slug: "deluxe-family-suite-valley-view",
      type: "double",
      price: 200,
      size: 300,
      capacity: 5,
      pets: true,
      breakfast: false,
      featured: true,
      description:
        "Discover pure bliss in our Deluxe Family Suite with Valley View, an epitome of luxury and comfort tailored for your family's perfect getaway. This spacious suite harmoniously marries stunning vistas with modern design. Step into a refined living area adorned with tasteful décor, setting the tone for relaxation. Unwind on plush furnishings while taking in panoramic valley views through expansive windows. The suite features a master bedroom with a sumptuous king-sized bed ensuring privacy and togetherness. Modern amenities like a flat-screen TV, high-speed Wi-Fi, and a well-appointed en-suite bathroom complete the experience. Immerse yourself in nature's beauty, whether savoring a morning coffee or watching the sunset. Reconnect with your loved ones and create cherished memories in our Deluxe Family Suite with Valley View.",
      extras: [
        "Toiletries, Towels",
        "Full-size executive writing desk",
        "Coffee and tea-making facilities, Mini - Fridge",
        "24X7 Room Service",
        "High-speed Wi-Fi and broadband Internet access",
        "Laundry Service, King Size Bed, Extra Bed on request"
      ],
      images: [
        {
          fields: {
            file: {
              url: deluxeFamilySuiteBanner
            }
          }
        },
        {
          fields: {
            file: {
              url: deluxeFamilySuite1
            }
          }
        },
        {
          fields: {
            file: {
              url: deluxeFamilySuite2
            }
          }
        },
        {
          fields: {
            file: {
              url: deluxeFamilySuite3
            }
          }
        }
      ]
    }
  }
];
