import React from 'react';
import './NearByTitle.css';
const NearByTitle = ({title}) => {
  return (
    <div className='nearby-title'>
        <h4>{title}</h4>
        <div />
    </div>
  )
}

export default NearByTitle;