import React from 'react'

const Title = ({title, subtitle}) => {
  return (
    <div className='subsection-title'>
        <h4>{title}</h4>
        <h2>{subtitle}</h2>
        <div />
    </div>
  )
}

export default Title;