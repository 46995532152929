// src/pages/About.js
import React, { useState, useEffect } from "react";
import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import "./About.css";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import Title from "../../components/Title";
import TitleWithSubtitle from "../../components/TitleWithSubtitle";
// import { ContentPhoto } from "./ContentPhoto";


import { AboutUsContentPart1, AboutUsContentPart2 } from "../../components/Constants";

import reception from "../../images/mandu-photos/malwa-retreat.jpg";
import about2 from "../../images/about/about-us-2.jpg";
import logo from "../../images/Untitled-design-modified.png";

const About = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, [screenWidth]);
  return (
    <>
      <Hero hero="aboutHero">
        <Banner title="About Us" subtitle="Welcome to Malwa Retreat Mandu">
          <Link
            to="/nearby"
            className="btn-primary"
            style={{
              boxShadow:
                "0 0 10px 2px rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
              textShadow: "none",
            }}
          >
            View Nearby Sites
          </Link>
        </Banner>
      </Hero>
      <div className="about">
        <img src={logo} className="about-logo" />
        <div />
        <TitleWithSubtitle title="Malwa Retreat" subtitle="The Heart Of Incredible Malwa" className="about-heading" />
        <Row className="responsive-grid">
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container">
            <img src={reception} alt="" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container">
            <p>{AboutUsContentPart1}</p>
          </div>
        </Col>
      </Row>
      <Row className="responsive-grid01">
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container01">
            <p>{AboutUsContentPart2}</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container01">
            <img src={about2} alt="hindolaMahal" />
          </div>
        </Col>
      </Row>
        {/* <div className="content">
          <div className="content-part1">
            Established in the year 1965, Malwa Retreat in Mandu Dhar is a top
            player in the hospitality segment creating great value for tourists
            and explorer. This well known establishment acts as a one-stop
            destination servicing customers both from local as well as othet
            parts aof India and abroad. Over the course of it's journey ,we have
            established a firm foothold in its industry. The belief that
            customer satisfaction is as important as their products and
            services, have helped us garner a vast base of customers, which
            continues to grow by the day.
          </div>
          {screenWidth > 1100 ? <hr className="about-hr" /> : <ContentPhoto />}
          <div className="content-part2">
            We employ individuals that are dedicated towards their respective
            roles and put in a lot of effort to achieve the common vision and
            larger goals of the company. In the near future we aim to expand
            it's line of products and services to cater to a larger client base.
            In Mandu, Dhar this establishment occupies a prominent location. It
            is an effortless task in commuting our property as there are various
            modes of transport readily available. It is at Main Road, Near
            Roopmati Hotel, which makes it easy for first-time visitors in
            locating this establishment.
          </div>
        </div> */}
      </div>
      {/* {screenWidth > 1100 && <ContentPhoto />} */}
    </>
  );
};

export default About;
