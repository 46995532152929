import React from 'react';
import './InstaTitle.css';
const InstaTitle = ({title}) => {
  return (
    <div className='insta-title'>
        <h4>{title}</h4>
        <div></div>
    </div>
  )
}

export default InstaTitle;