import React from "react";
import HeroSlider, { Overlay, Slide } from "hero-slider";
import HomeImage01 from "../../images/Banner/AboutUs.jpg";
import HomeImage02 from "../../images/Banner/Home.jpg";
import "./HeroCarousel.css";
import HeroTitle from "./HeroTitle/HeroTitle";
import Subtitle from "./Subtitle/Subtitle";
import Wrapper from "./Wrapper/Wrapper";

import banner1 from "../../images/BannerBackup/Banner1.jpg";
import banner3 from "../../images/BannerBackup/Banner3.jpg";
import banner4 from "../../images/BannerBackup/Banner4.jpg";
import banner5 from "../../images/BannerBackup/Banner5.jpg";
import banner6 from "../../images/BannerBackup/Banner6.jpg";
import banner7 from "../../images/BannerBackup/Banner7.jpg";

const HeroCarousel = () => {
  return (
    <HeroSlider
      height={"100vh"}
      autoplay
      accessibility={{
        shouldDisplayButtons: true,
        // shouldSlideOnArrowKeypress: true
      }}
      animations={{
        slidingAnimation: "fade",
        sliderFadeInDuration: 500,
      }}
      controller={{
        initialSlide: 1,
        slidingDuration: 1000,
        slidingDelay: 1,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide),
      }}
    >
      <Overlay>
        <Wrapper>
          <HeroTitle>Malwa Retreat</HeroTitle>
          <Subtitle>Experience Legacy, Embrace Serenity</Subtitle>
        </Wrapper>
      </Overlay>

      <Slide
        background={{
          backgroundImageSrc: HomeImage01,
        }}
      />

      <Slide
        background={{
          backgroundImageSrc: HomeImage02,
        }}
      />

      <Slide
        background={{
          backgroundImageSrc: banner1,
        }}
      />

      <Slide
        background={{
          backgroundImageSrc: banner3,
        }}
      />

      <Slide
        background={{
          backgroundImageSrc: banner4,
        }}
      />

      <Slide
        background={{
          backgroundImageSrc: banner5,
        }}
      />

      <Slide
        background={{
          backgroundImageSrc: banner6,
        }}
      />

      <Slide
        background={{
          backgroundImageSrc: banner7,
        }}
      />
    </HeroSlider>
  );
};

export default HeroCarousel;
