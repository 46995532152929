import React, { createContext, useState, useEffect } from "react";
import items from "./data";

export const RoomContext = createContext();

export const RoomProvider = ({children}) => {
  const [roomInfo, setRoomInfo] = useState({
    rooms: [],
    featuredRooms: [],
    loading: true
  });

  useEffect(()=>{
    let rooms= formatData(items);
    let featuredRooms = rooms.filter(room=> room.featured===true);
    setRoomInfo({
      rooms,
      featuredRooms,
      loading: false
    });
  },[]);

  const formatData = (items) => {
    let tempItems = items.map(item =>{
      let id = item.sys.id;
      let images = item.fields.images.map(image=>
        image.fields.file.url);
      
      let room ={...item.fields, images, id};
      // console.log(room);
      return room;
    });
    return tempItems;
  };

  const getRoom= slug=>{
    let tempRooms= [...roomInfo.rooms];
    return tempRooms.find(room=>room.slug===slug);
  }

  return (
    <RoomContext.Provider value={{...roomInfo, getRoom}}>
        {children}
    </RoomContext.Provider>
  );
};


