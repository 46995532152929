import React from 'react';
import './ContactTitle.css';
const ContactTitle = ({title}) => {
  return (
    <div className='contact-title'>
        <h4>{title}</h4>
        <div />
    </div>
  )
}

export default ContactTitle;