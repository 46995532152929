import React from "react";
import emailjs from 'emailjs-com';
import "./ContactForm.css";
import ContactInfo from "../ContactInfo/ContactInfo";

const ContactForm = () => {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_EMAIL_USER_ID
    ).then(
      (result) => {
        console.debug('Email sent successfully:', result.text);
      },
      (error) => {
        console.error('Error sending email:', error.text);
      }
    );
  };

  return (
    <div className="contact-container">
      <div className="map-container">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.941149345561!2d75.39409987529412!3d22.35585087964673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396241b62b6618ab%3A0x40aed806584d9b7!2sMalwa%20Retreat%2C%20Mandu!5e0!3m2!1sen!2sin!4v1692196070191!5m2!1sen!2sin"
          title="location-map"
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>

      <div className="form-container">
        <div className="form-fields">
          <form onSubmit={sendEmail}>
            <input type="text" name="name" placeholder="Your Name" required />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <input type="text" name="subject" placeholder="Subject" required />
            <textarea
              name="message"
              placeholder="Your Message"
              required
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
