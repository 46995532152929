import styled from "styled-components";
import defaultImg from "../images/room-1.jpeg";

const StyledHero = styled.header`
min-height: 60vh;
background: url(${props=> (props.img? props.img : defaultImg)}) center/cover no-repeat;
display: flex;
align-items: center;
justify-content: center;
position: relative;

&::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
`
export default StyledHero;
