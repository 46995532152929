import React from "react";
import footerlogo from "../images/Untitled-design-modified.png";
import { Link } from "react-router-dom";
import { SendOutlined } from '@ant-design/icons';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import emailjs from 'emailjs-com';

const Footer = () => {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_EMAIL_USER_ID
    ).then(
      (result) => {
        console.debug('Email sent successfully:', result.text);
      },
      (error) => {
        console.error('Error sending email:', error.text);
      }
    );
  };
  return (
    <div className="footer-head">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={footerlogo} alt="Logo" />
        </div>
        <div className="footer-section footer-section-links">
          <h3>Pages</h3>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/gallery">Gallery</Link>
          <Link to="/rooms">Rooms</Link>
        </div>
        <div className="footer-section contact-info">
          <h3>Contact Us</h3>
          <div className="contact-address">
          Address: Mandav, Madhya Pradesh
          </div>
          <div className="contact-telephone">Telephone: 072922 63221</div>
          <div className="contact-email">Email: enquiry@malwaretreatmandu.com</div>
        </div>
        <div className="footer-section newsletter-container">
          <h3>Want to hear from us?</h3>
          <form className="subscribe-form" onSubmit={sendEmail}>
          <input type="email" placeholder="Enter your email" />
          <button type="submit" className="icon-button">
            <SendOutlined />
          </button>
          </form>
        </div>
        <div className="footer-section media-links">
          <h3>Follow us on </h3>
          <div className="media-icons">
              <a href="https://www.facebook.com/profile.php?id=61550987494293&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                  <FacebookIcon className="fab" />
              </a>
              <a href="https://www.instagram.com/malwa_retreat_mandu" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon className="insta" />
              </a>
              <a href="https://www.youtube.com/malwa_retreat_mandu" target="_blank" rel="noopener noreferrer">
                  <YoutubeIcon className="yt" />
              </a>
          </div>
      </div>

      </div>
</div>
  );
};

export default Footer;
