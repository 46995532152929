import React from "react";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import Services from "../components/Services/Services";
import FeaturedRooms from "../components/FeaturedRooms";
import HeroCarousel from "../components/Hero/HeroCarousel";
import TestimonialCarousel from "../components/Testimonials/TestimonialCarousel";
import Instagram from "../components/Instagram/Instagram";

const home = () => {
  return (
    <>
      <HeroCarousel />
      <Services />
      <FeaturedRooms />
      <TestimonialCarousel />
      <Instagram />
    </>
  );
};

export default home;
