import React, { useState } from "react";
import logo from "../images/Untitled-design-modified.png";
import sublogo from "../images/written part-modified.png";
import { FaAlignRight, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const [buttonClicked, setButtonClicked] = useState(false);

  const handleClick = () => {
    setButtonClicked(!buttonClicked);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <div className="logo">
            <img src={logo} alt="Malwa Retreat" />
            </div>
          </Link>
          {/* <div className="sublogo">
            <img src={sublogo} alt="The heart of incredible Malwa" />
          </div> */}
          <div>
            {buttonClicked ? (
              <button
                type="button"
                className="nav-btn"
                onClick={handleClick}
              >
                <FaTimes className="nav-icon" />
              </button>
              ) : (
              <button
                type="button"
                className="nav-btn"
                onClick={handleClick}
              >
                <FaAlignRight className="nav-icon" />
              </button>
            )}
          </div>
        </div>
        <ul className={open ? "nav-links show-nav" : "nav-links"}>
          <li>
            <Link to="/" onClick={handleClick}>Home</Link>
          </li>
          <li>
            <Link to="/rooms" onClick={handleClick}>Rooms</Link>
          </li>
          <li>
            <Link to="/about" onClick={handleClick}>About</Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleClick}>Contact</Link>
          </li>
          <li>
            <Link to="/gallery" onClick={handleClick}>Gallery</Link>
          </li>
          <li>
            <Link to="/nearby" onClick={handleClick}>Neaby Sites</Link>
          </li>
          <li>
          <Link to="/book-now" className="book-now--link btn-primary" onClick={handleClick}>Book Now</Link>
          </li>
        </ul>
        <div className="book-now--btn btn-primary">
          {/* <a 
          href="https://www.agoda.com/en-in/mpt-malwa-retreat-mandu/hotel/mandu-in.html"
          target="blank">
            Book Now  
          </a> */}
          <Link to="/book-now">Book Now</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
