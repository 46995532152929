// src/pages/Gallery/Gallery.js
import React, {useState} from "react";
import "./Gallery.css";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";

import image1 from "../../images/Gallery/Gallert122.jpg";
import image2 from "../../images/Gallery/Gallery120.jpg";
import image3 from "../../images/Gallery/Gallery121.jpg";
import image4 from "../../images/Gallery/gallery128 (1).jpg";
import image5 from "../../images/Gallery/gallery1210.jpg";
import image6 from "../../images/Gallery/gallery1214.jpg";
import image7 from "../../images/Gallery/gallery126.jpg";
import image8 from "../../images/Gallery/gallery127.jpg";
import image9 from "../../images/Gallery/gallery128.jpg";
import image10 from "../../images/Gallery/gallery129.jpg";
import image11 from "../../images/Gallery/gallery13.jpg";
import image12 from "../../images/Gallery/gallery15.jpg";
import image13 from "../../images/Gallery/Gallery17.jpg";
import image14 from "../../images/Gallery/Gallery18.jpg";
import image15 from "../../images/Gallery/Gallery19.jpg";
import image16 from "../../images/Gallery/Gallery20.jpg";
import image17 from "../../images/Gallery/Gallery21.jpg";
import image18 from "../../images/Gallery/Gallery22.jpg";
import image19 from "../../images/Gallery/Gallery23.jpg";
import image20 from "../../images/Gallery/Gallery27.jpg";

import GalleryHero from "../../components/Gallery/GalleryHero";
import "../../components/Gallery/GalleryHero.css";
import GalleryTitle from "../../components/Gallery/GalleryTitle";


const Gallery = () => {
  const [modalImage, setModalImage] = useState(null);
  const [idx, setIdx] = useState(null);

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20
  ];

  const handleClick = (src, index) => {
    setModalImage(src);
    setIdx(index);
  };

  const closeModal = () => {
    setModalImage(null);
    setIdx(null);
  };

  const nextImage = () => {
    const nextIndex = (idx + 1) % images.length;
    setModalImage(images[nextIndex]);
    setIdx(nextIndex);
  };

  const prevImage = () => {
    const prevIndex = (idx - 1 + images.length) % images.length;
    setModalImage(images[prevIndex]);
    setIdx(prevIndex);
  };

  return (
    <>
      <GalleryHero hero="gallery">
        <Banner title="Gallery">
          <Link
            to="/"
            className="btn-primary"
            style={{
              boxShadow:
                "0 0 10px 2px rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
              textShadow: "none",
              zIndex: 6,
            }}
          >
            Return Home
          </Link>
        </Banner>
      </GalleryHero>
      <GalleryTitle title="Photos" />
      <section className="roomslist">
        <div className="roomslist-center">
          {images.map((image, index) => (
            <div className="img-containerGal image-item" key={image} onClick={() => handleClick(image, index)}>
              <img src={image} alt={`Image ${index + 1}`} />
            </div>
          ))}
        </div>

        {modalImage && (
        <div className="modal" onClick={closeModal}>
          <button className="nav-button l" onClick={(e) => { e.stopPropagation(); prevImage(); }}>Prev</button>
          <img src={modalImage} alt="image" className="modal-image" />
          <button className="nav-button r" onClick={(e) => { e.stopPropagation(); nextImage(); }}>Next</button>
        </div>
      )}
      </section>
    </>
  );
};

export default Gallery;
