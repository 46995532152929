import React, { useState, useEffect } from "react";
import "./Testimonial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { faQuoteLeftAlt } from "@fortawesome/free-solid-svg-icons";
//import googleReview from "../../images/review-icons/Google.png";

const testimonials = [
  {
    name: "Saday Singh",
    //position: "Marketing",
    photo:
      "https://dotgo.com/rcsbotdirectory/image/bot/200/logoimage/2/3109c145-af01-4144-8fbf-3a2633798de6.png",
    text: "This property is situated on the mountain cliff. The view is awsome. Rooms are of good size and one which are on 1st floor have balconies too. Property is little old and shows its age. Rooms are nice and clean. It has a full service restraunts and room service.And if u are a pet lover and love to travel with pets, then this is a place for u. This property also allows pets.",
  },
  {
    name: "Akash Agarwal",
    //position: "Marketing",
    photo: "https://www.pngitem.com/pimgs/m/522-5225274_leave-us-a-google-review-circle-hd-png.png",
    text: "Fantastic view of lake near the hotel. Each room and cottage is over looking lake and lush green pictures perfect view of greenery. Food in the resturent is good and we got a very good service from staff.Room can be better specially beds and bathrooms. But it was comfortable and clean."
  },
  {
    name: "Prakash Nagariya",
    //position: "Marketing",
    photo: "https://w7.pngwing.com/pngs/598/909/png-transparent-makemytrip-flight-travel-hotel-india-travel-thumbnail.png",
    text: "The mpt stay was very comfortable and the staff was very humble, soft spoken and helpful in nature. Hospitality was awesome as the service provided was very prompt.Rooms were adequately well kept and decent. Food was palatable.I loved every bit of stay here. Very close to all important places for visit, literally centrally placed."
  },
  {
    name: "Devendra Asalkr",
    //position: "Marketing",
    photo: "https://www.pngitem.com/pimgs/m/171-1715823_round-tripadvisor-icon-png-transparent-png.png",
    text: "I Devendra Asalkar with my 7 members of family have visited Hotel Malwa retreat Mandu by MP tourism on 25th and 26th December 2020.Our experience and stay @ Hotel was awesome.We enjoyed stay in neat and clean rooms. Also garden at hotel is well maintained and hotel rooms & location of hotel is close to nature. We enjoyed tasty breakfast,lunch & dinner made by Ustaad or chef Mr Bharat."
  }
];

const TestimonialCarousel = () => {
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIdx((prevIdx) =>
        prevIdx === testimonials.length - 1 ? 0 : prevIdx + 1
      );
    }, 5200);
    return () => clearInterval(interval); // Clean up on component unmount
  }, []);

  const { name, position, photo, text } = testimonials[idx];

  return (
    <div className="testimony">
      <div className={"testimonials-container"}>
        <div className="progress-bar"></div>
        <FontAwesomeIcon icon={faQuoteLeftAlt} className="faQuoteLeftAlt"></FontAwesomeIcon>
        <FontAwesomeIcon icon={faQuoteRight} className="faQuoteRight"></FontAwesomeIcon>
        <p className="testimonial">{text}</p>
        <div className="user">
          <img
            src={photo}
            alt="user"
            className="user-image"
          />
          <div className="user-details">
            <h4 className="username">{name}</h4>
            {/* <p className="role">{position}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
