import React from 'react';
import './GalleryTitle.css';
const GalleryTitle = ({title}) => {
  return (
    <div className='gallery-title'>
        <h4>{title}</h4>
        <div />
    </div>
  )
}

export default GalleryTitle;