import React from "react";
import { Card, Col, Row, Typography } from "antd";
import {
  CoffeeOutlined,
  HomeOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";
import "./Services.css";
//import Title from "../Title";

const { Title, Paragraph } = Typography;
const cardStyle = {
  width: 240,
  textAlign: "center",
  margin: "10px auto",
  backgroundColor: "#f8f9fa",
};

const IconCard = ({ Icon, title }) => (
  <Card style={cardStyle}>
    <Icon style={{ fontSize: "36px", color: "#08c" }} />
    <h3>{title}</h3>
  </Card>
);

export default function Services() {
  return (
    <>
      {/* <Title title="Welcome to Malwa Retreat" /> */}
      <div className="Services">
        <Row className="ant-row-design">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Paragraph className="Paragraph">Welcome to Malwa Retreat</Paragraph>
            <h2 className="ServicesTitle" level={2}>A New Vision of Luxury Resort</h2>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6} className="large-screen">
            <IconCard Icon={CoffeeOutlined} title="Restaurant" />
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6} className="large-screen">
            <IconCard Icon={HomeOutlined} title="Fireplace" />
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6} className="large-screen">
            <IconCard Icon={HomeOutlined} title="Cozy Rooms" />
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6} className="large-screen">
            <IconCard Icon={ClockCircleOutlined} title="Room Service" />
          </Col>
        </Row>
      </div>
    </>
  );
}
