import React from 'react';
import './BookNowTitle.css';
const BookNowTitle = ({title}) => {
  return (
    <div className='book-now-title'>
        <h4>{title}</h4>
        <div />
    </div>
  )
}

export default BookNowTitle;