import React from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import RoomContainer from "../components/RoomContainer";
import RoomsTitle from "../components/RoomsTitle/RoomsTitle";

const Rooms = () => {
  return (
    <>
    <Hero hero="roomsHero">
      <Banner title="our rooms">
      </Banner>
    </Hero>
    <RoomsTitle title="Rooms"/>
    <RoomContainer/>
    </>
  );
};

export default Rooms;
