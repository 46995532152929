export const AboutBazBahadur = "Nestled 5 kilometers from Mandu Bus Stand, Baz Bahadur Palace in Madhya Pradesh, a segment of the Rewa Kund Monuments, beckons history enthusiasts. Erected in 1508 by Sultan Nasir-ud-Din, this architectural marvel harmoniously blends Rajput and Mughal styles. Adorned with Persian inscriptions, the palace's significance deepens due to its ties to King Baz Bahadur's romantic liaison with Roopmati, a gifted songstress. Its grandeur comprises a central courtyard fringed by chambers and encompassing an ornate reservoir. The palace doubled as a court during 1555-1561. Chhatris grace its terrace, while a resonant hall echoes the past. Visitors can explore from 8 AM to 6 PM, with entry priced at Rs. 25 for Indians and Rs. 300 for foreigners, gaining access to a historical tapestry frozen in time.";

export const Abouthindolal ="Hindola Mahal in Mandu, known as the Swinging Palace, is a renowned tourist spot. It once served as Ghiyas-al-Din Sultan's lavish royal court, showcasing the opulence of Durbar halls and epitomizing Malwa-Sultanate architecture. The palace's ingenious sculptural design and excellent craftsmanship magnetize numerous annual visitors. Crafted mainly from sandstone, it features meticulously carved columns and subterranean chambers with hot and cold water provisions.Today, Hindol Mahal stands as a T-shaped edifice, serving as an audience hall or open-air theater. Originally believed to be built in 1425 under Hoshang Shah, it underwent modifications during Ghiyasuddin Khilji's 15th-century rule. Its architectural simplicity distinguishes it among monuments. Munj Talao's ruins envelop the northern side, providing an engaging historical exploration. A haven for history and architecture enthusiasts, Hindola Mahal's charm lies in its exquisite beauty and scenic setting.";

export const AboutRaniRoopmati = "Roopmati Pavilion, dating to the 16th century, is an essential facet of Mandu's Rewa Kund Group of monuments, poised on a 365-meter precipice offering sweeping views of the Nimar Valley. Amid Mandu's historical wealth, it holds a compelling presence, its narrative intricately linked with the poignant love story of Prince Baz Bahadur and Roopmati. Initially designed as a military observation post, it later transformed into Roopmati's residence. Managed by the Archaeological Survey of India, the Rani Roopmati Pavilion showcases splendid Afghan sandstone architecture, evolving across phases, with a capacious lower hall and paired rooms flanking it. Subsequent expansion on the western side facilitated river views and religious practices. Subterranean passages boast arched openings, while the western terrace houses Rewa Kund, a vast monsoon rainwater reservoir. The panorama of the Narmada River flowing 305 meters below is breathtaking, making it an ideal spot for sunset admiration. Opening Hours: 8 AM - 6 PM.";

export const AboutJahazMahal = "Jahaz Mahal, a captivating architectural marvel in Mandu, seamlessly melds Afghan, Mughal, Hindu, and Mesopotamian styles, reshaping the local tourism landscape. Amidst reflective waters, the palace appears to float, earning its moniker. It shares history with Mandu, offering 15,000 royal women autonomy and seclusion, steeped in opulence. The palace features a main entrance on the east, six arched openings on each side, upheld by stone brackets and a cornice. Within, three spacious halls interconnect with corridors leading to narrow rooms. A southern room even houses a water channel, believed to have supplied a stunning cistern in the north. Pavilion extensions overlook Munj Talao, potentially for the king's harem, concealed by curtain-draped arches. Beyond its allure, Jahaz Mahal hosts the vibrant Mandu festival, showcasing adventure, music, lights, and balloon festivities, making it a must-visit for Madhya Pradesh enthusiasts.";

export const AboutDelhiDarwaza = "Delhi Darwaza, the vital northern gateway in Mandu, stands as a testament to its historical significance. Serving as the main entrance and a prime target for potential attacks, it was reserved for the royal family. Though now in ruins, its past grandeur is evident with five decorative arches, red stone construction, and a ceramic stone dome painted blue. The gatekeepers' chambers once lined the covered pathway. Past the gate, three additional arches were added later, leading to two divergent roads—one to the royal precincts and the other to Mandav village. This gate remains a remarkable relic of Mandu's history.";

export const AboutNilkanth ="Nilkanth Mahadev Mandir, situated 3 km from Mandu Road in Madhya Pradesh, is a Hindu temple on the edge of a steep gorge near Sagar Lake. This ancient shrine venerates Lord Shiva. Originally built in Islamic style, it was later transformed into a Mughal-era pleasure house but was reclaimed by Lord Shiva. The sanctum holds a Shivaling, accessible via a metal ladder, with a natural stream flowing behind it. Pilgrims make wishes by placing leaves in a water labyrinth in the sacred pond within the temple's courtyard. Nearby stands Nilkanth Mahal, a Mughal palace with scenic views and inscriptions. Temple hours are 6 AM to 7 PM, with free entry.";

export const AboutJamaMasjid = "The Jama Masjid, 500 m from Mandu Bus Stand, is a historic mosque in Mandu. Part of the Central Group Monuments, it stands opposite Ashrafi Mahal, a top Mandu tourist spot. Constructed during Hoshang Shah's reign and completed by Mahmud Khilji in 1454, it imitates the Damascus mosque. The Mughal-style mosque boasts a grand entrance, marble details, and a courtyard with colonnaded verandas. Inside, 58 small domes and three large ones adorn the prayer halls, with a marble mihrab and raised pulpit. Open from 8 AM to 6 PM, it charges Rs. 25 for Indians and Rs. 100 for foreigners."

export const AboutDilawasMosque = "The Mosque of Dilawar Khan in Mandu is a unique blend of Muslim and Hindu architectural styles. Constructed in 1405, it's the earliest mosque in the area. The courtyard is surrounded by a colonnade, while the prayer hall showcases bold Hindu artistic influences in its ceilings, columns, and gates. Notably, the mosque was built using stones from desecrated temples. Facing east, at the southern end, is the dilapidated Nahar Jharokha or Tiger Balcony, named for its tiger-like form. When exploring historic Mandu, a visit to this mosque is a must to witness this intriguing fusion of architectural styles.";


export const AboutUsContentPart1 = "Established in the year 1965, Malwa Retreat in Mandu Dhar is a top player in the hospitality segment creating great value for tourists and explorer. This well known establishment acts as a one-stop destination servicing customers both from local as well as other parts aof India and abroad. Over the course of it's journey ,we haveestablished a firm foothold in its industry. The belief that customer satisfaction is as important as their products and services, have helped us garner a vast base of customers, which continues to grow by the day."

export const AboutUsContentPart2 = "We employ individuals that are dedicated towards their respective roles and put in a lot of effort to achieve the common vision and larger goals of the company. In the near future we aim to expand it's line of products and services to cater to a larger client base. In Mandu, Dhar this establishment occupies a prominent location. It is an effortless task in commuting our property as there are various modes of transport readily available. It is at Main Road, NearRoopmati Hotel, which makes it easy for first-time visitors in locating this establishment."