import React from 'react';
import loafingGif from "../images/gif/loading-arrow.gif";

const Loading = () => {
  return (
    <div className="loading">
      <h4>rooms data loading...</h4>
      <img  src={loafingGif} alt=""/>
    </div>
  )
}

export default Loading;