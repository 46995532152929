import React from 'react';
import './RoomsTitle.css';
const RoomsTitle = ({title}) => {
  return (
    <div className='rooms-title'>
        <h4>{title}</h4>
        <div />
    </div>
  )
}

export default RoomsTitle;