import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import "./NearBy.css";
import "./NearByAlt.css";
import BazBahadur from "../../images/NearBy/BazbahadurPalace.jpg";
import hindolaMahal from "../../images/NearBy/hindolaMahal.jpg";
import Raniroopmati from "../../images/NearBy/Raniroopmati.jpg";
import jahazMahal from "../../images/NearBy/jahazMahal.jpg";
import DelhiDarwaza from "../../images/NearBy/Delhi Darwaza.jpg";
import Nilkanth from "../../images/NearBy/NIlkanth.jpg";
import JamaMasjid from "../../images/NearBy/JamaMasjid.jpg";
import Mosque from "../../images/NearBy/Mosque.jpeg";
import { AboutBazBahadur } from "../Constants";
import { Abouthindolal } from "../Constants";
import { AboutRaniRoopmati } from "../Constants";
import { AboutJahazMahal } from "../Constants";
import { AboutDelhiDarwaza } from "../Constants";
import { AboutNilkanth } from "../Constants";
import { AboutJamaMasjid } from "../Constants";
import { AboutDilawasMosque } from "../Constants";
import NearByHero from "./NearByHero/NearByHero";
import NearByTitle from "./NearByTitle/NearByTitle";
import "../NearBy/NearByHero/NearByHero.css";
import Banner from "../Banner";

function NearBy() {
  return (
    <>
      <NearByHero hero="nearby-Places">
        <Banner title="Local Attarctions">
          <Link
            to="/"
            className="btn-primary"
            style={{
              boxShadow:
                "0 0 10px 2px rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
              textShadow: "none",
              zIndex: 6,
            }}
          >
            return home
          </Link>
        </Banner>
      </NearByHero>
      <NearByTitle title="Local Attractions" />
      <Row className="responsive-grid">
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container">
            <img src={BazBahadur} alt="BazbahadurPalace" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container">
            <h3>Baz Bahadur Palace</h3>
            <p>{AboutBazBahadur}</p>
          </div>
        </Col>
      </Row>
      <Row className="responsive-grid01">
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container01">
            <h3>Hindola Mahal</h3>
            <p>{Abouthindolal}</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container01">
            <img src={hindolaMahal} alt="hindolaMahal" />
          </div>
        </Col>
      </Row>
      <Row className="responsive-grid">
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container">
            <img src={Raniroopmati} alt="Raniroopmati" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container">
            <h3>Roopmati Pavillion</h3>
            <p>{AboutRaniRoopmati}</p>
          </div>
        </Col>
      </Row>
      <Row className="responsive-grid01">
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container01">
            <h3>Jahaz Mahal</h3>
            <p>{AboutJahazMahal}</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container01">
            <img src={jahazMahal} alt="jahazMahal" />
          </div>
        </Col>
      </Row>

      <Row className="responsive-grid">
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container">
            <img src={DelhiDarwaza} alt="Darwaza" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container">
            <h3>Delhi Darwaza</h3>
            <p>{AboutDelhiDarwaza}</p>
          </div>
        </Col>
      </Row>

      <Row className="responsive-grid01">
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container01">
            <h3>Nilkanth Mandir</h3>
            <p>{AboutNilkanth}</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container01">
            <img src={Nilkanth} alt="jahazMahal" />
          </div>
        </Col>
      </Row>

      <Row className="responsive-grid">
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container">
            <img src={JamaMasjid} alt="Darwaza" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container">
            <h3>Jama Masjid</h3>
            <p>{AboutJamaMasjid}</p>
          </div>
        </Col>
      </Row>

      <Row className="responsive-grid01">
        <Col xs={24} sm={24} md={12} lg={16}>
          <div className="text-container01">
            <h3>Dilawar Khan Mosque</h3>
            <p>{AboutDilawasMosque}</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="image-container01">
            <img src={Mosque} alt="jahazMahal" />
          </div>
        </Col>
      </Row>
    </>    
  );
}

export default NearBy;
