import React from 'react';
import './App.css';
import Home from "./pages/Home";
import Rooms from "./pages/Rooms";
import SingleRoom from "./pages/SingleRoom";
import About from "./pages/About/About";
import ContactUs from "./pages/ContactUs/ContactUs";
import Gallery from "./pages/Gallery/Gallery";
import Error from "./pages/Error";
import Footer from "./components/Footer";
import NearBy from './components/NearBy/NearBy';
import BookNow from './pages/BookNow/BookNow';

import { Route, Routes } from "react-router-dom";
import Navbar from './components/Navbar';
// import Header from './components/Header/Header';

function App() {
  return (
    <div>
      <Navbar />
      {/* <Header /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/rooms/" element={<Rooms />} />
        <Route exact path="/rooms/:slug" element={<SingleRoom />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/nearby" element={<NearBy />} />
        <Route exact path="/book-now" element={<BookNow />} />
        <Route exact path="/*" element={<Error />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
