import React from 'react';
import ContactUsHero from '../../components/ContactUsHero/ContactUsHero';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import "./ContactUs.css";
import ContactForm from '../../components/ContactForm/ContactForm';
import Banner from '../../components/Banner';
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <>
    <ContactUsHero hero="contactHero">
    <Banner title="Contact Info">
          <Link
            to="/"
            className="btn-primary"
            style={{
              boxShadow:
                "0 0 10px 2px rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
              textShadow: "none",
              zIndex: 6,
            }}
          >
            Return Home
          </Link>
        </Banner>
    </ContactUsHero>
    <ContactInfo />
    <ContactForm />
    </>
  )
}

export default ContactUs;