import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import Room1 from "../../images/mandu-photos/DoubleRoom01.jpg";
import Tent from "../../images/Banner/Rooms.jpg";
import Room2 from "../../images/mandu-photos/malwa-retreat 01.jpg";
import Room3 from "../../images/mandu-photos/ROOM02.jpg";
import Room4 from "../../images/Banner/BannerNearby.jpg";
import "./Instagram.css";
import InstaTitle from './InstaTitle';

const Instagram = () => {
  const [modalImage, setModalImage] = useState(null);
  const [idx, setIdx] = useState(null);

  const images = [Room1,Tent,Room2, Room3, Room4];

  const handleClick = (src, index) => {
    setModalImage(src);
    setIdx(index);
  };

  const closeModal = () => {
    setModalImage(null);
    setIdx(null);
  };

  const nextImage = () => {
    const nextIndex = (idx + 1) % images.length;
    setModalImage(images[nextIndex]);
    setIdx(nextIndex);
  };

  const prevImage = () => {
    const prevIndex = (idx - 1 + images.length) % images.length;
    setModalImage(images[prevIndex]);
    setIdx(prevIndex);
  };

  return (
    <div>
      <InstaTitle title="Photos"/>
      <div className="image-gallery">
        {images.map((src, index) => (
          <div className="image-container" key={src} onClick={() => handleClick(src, index)}>
            <img src={src} alt="Insta images" className="gallery-image" />
            <div className="icon-overlay">
              <a href="https://www.instagram.com/malwa_retreat_mandu" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="3x"/>
              </a>
            </div>
          </div>
        ))}
      </div>
      {/* {modalImage && (
        <div className="modal" onClick={closeModal}>
          <button className="nav-button left" onClick={(e) => { e.stopPropagation(); prevImage(); }}>Prev</button>
          <img src={modalImage} alt="image" className="modal-image" />
          <button className="nav-button right" onClick={(e) => { e.stopPropagation(); nextImage(); }}>Next</button>
        </div>
      )} */}
    </div>
  );
};

export default Instagram;
