import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import { RoomContext } from "../Context";
import StyledHero from "../components/StyledHero";


const SingleRoom = () => {
  let { getRoom } = useContext(RoomContext);
  const { slug } = useParams();
  const room = getRoom(slug);
  if (!room) {
    return (
      <div className="error">
        <h3>no such room could be found...</h3>
        <Link to="/rooms" className="btn-primary">
          back to rooms
        </Link>
      </div>
    );
  }

  const {
    name,
    description,
    capacity,
    size,
    price,
    extras,
    breakfast,
    pets,
    images,
  } = room;

  const [mainImg, ...defaultImg] = images;

  // useEffect(() => {},[]);
  return (
    <>
      <StyledHero img={mainImg}>
        <Banner title={`${name} room`}>
          <Link
            to="/rooms"
            className="btn-primary"
            style={{
              boxShadow:
                "0 0 10px 2px rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
              textShadow: "none",
            }}
          >
            back to rooms
          </Link>
        </Banner>
      </StyledHero>
      <section className="single-room">
        <div className="single-room-images">
          {defaultImg.map((item, index) => {
            return <img key={index} src={item} alt={name} />;
          })}
        </div>
        <div className="single-room-info">
          <article className="desc">
            <h3>details</h3>
            <p style={{ textAlign: 'justify' }}>{description}</p>
          </article>
          <article className="info">
            <h3>info</h3>
            {/* <h6>price : ${price}</h6>
            <h6>size : {size}SQFT</h6> */}
            <h5>
              max capacity :{" "}
              {capacity > 1 ? `${capacity} people` : `${capacity} person`}
            </h5>
            <h5>{pets ? "pets allowed" : "no pets allowed"}</h5>
            <h5>{breakfast && "free breakfast included"}</h5>
            <div className="book-now--btn btn-primary">
          {/* <a 
          href="https://www.agoda.com/en-in/mpt-malwa-retreat-mandu/hotel/mandu-in.html"
          target="blank">
            Book Now
          </a> */}
          <Link to="/book-now">Book Now</Link>
        </div>
          </article>
        </div>
      </section>
      <section className="room-extras">
        <ul className="extras">
          {extras.map((item, index) => {
            return <li key={index} style={{fontWeight: "bold"}}>- {item}</li>;
          })}
        </ul>
      </section>
    </>
  );
};

export default SingleRoom;
