import React from 'react';
import './ContactInfo.css';
import ContactTitle from './ContactTitle';

const ContactInfo = () => {
    return (
        <>
        <ContactTitle title="Contact Us"/>
        <div className="grid-container">
            <div className="grid-item hidden"></div>
            <div className="grid-item address">
                <label>Address:</label>
                <p>Main Road, near Sada Barrier, Mandav, Madhya Pradesh 454010</p>
            </div>
            <div className="grid-item phone">
                <label>Phone:</label>
                <p>+91 72922 63221</p>
            </div>
            <div className="grid-item email">
                <label>General Enquiry:</label>
                <p>enquiry@malwaretreatmandu.com</p>
            </div>
            <div className="grid-item website">
                <label>Whatsapp Number:</label>
                <p>+91 83495 18858</p>
            </div>
            <div className="grid-item hidden"></div>
        </div>
        </>
    );
};

export default ContactInfo;

