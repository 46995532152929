import React, {useContext} from 'react';
import { RoomContext } from '../Context';
import RoomsList from "./RoomList";
import Loading from './Loading';

const RoomContainer = () => {
  let {loading, rooms} = useContext(RoomContext);
  return (
    <>
        {loading? <Loading/>: <RoomsList rooms={rooms}/>}
    </>
  )
}

export default RoomContainer;