import React from 'react';
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import BookNowHero from '../../components/BookNow/BookNowHero';
import BookNowTitle from '../../components/BookNow/BookNowTitle';

import { Card } from "antd";

import { WhatsAppOutlined } from "@ant-design/icons";

import QRCode from '../../images/QRScanner.jpeg';

import './BookNow.css';

const cardStyle = {
  width: 220,
  textAlign: "center",
  margin: "3rem auto",
  backgroundColor: "#f8f9fa",
};

const IconCard = ({ Icon, title }) => (
  <Card style={cardStyle}>
    <Icon style={{ fontSize: "36px", color: "#08c" }} />
    <h3>{title}</h3>
  </Card>
);


const BookNow = () => {
  return (
    <>
      <BookNowHero hero="book-now">
        <Banner title="Book With Us">
          <Link
            to="/"
            className="btn-primary"
            style={{
              boxShadow:
                "0 0 10px 2px rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
              textShadow: "none",
              zIndex: 6,
            }}
          >
            Return Home
          </Link>
        </Banner>
      </BookNowHero>
      <BookNowTitle title="Book Now" />
      <div className="book-now-container">
      <div className="book-now-header">
        <h2 className="book-now-partner">Book from our partner websites</h2>
        <div />
      </div>
      <div className="book-now-button-container">
        <div className="mmt-btn btn-primary">
        <a 
          href="https://www.makemytrip.com/hotels/malwa_retreat_mandu-details-mandu.html"
          target="blank">
          Make My Trip
          </a>
        </div>
        <div className="agoda-btn btn-primary">
           <a 
          href="https://www.agoda.com/en-in/mpt-malwa-retreat-mandu/hotel/mandu-in.html"
          target="blank">
            Agoda 
          </a>
        </div>
      </div>
      <div className="book-now-header">
        <h2 className="book-now-directly">Book with us directly</h2>
        <div />
      </div>
      <div className="phone-number">
      <IconCard Icon={WhatsAppOutlined} title="8349518858" /> 
      </div>
      <div className="book-now-scanner">
      <div className="book-now-header">
        <h2 className="book-now-scan">Scan & Pay</h2>
        <div />
      </div>
      <div className="book-now-scanner-image">
        <img className="scanner-image" src={QRCode} alt="UPI scanner" />
      </div>
      </div>
      </div>
    </>
    
  );
}

export default BookNow;